import React, { useEffect, useState } from "react";

import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import TimezoneSelect from 'react-timezone-select'
import { useDispatch } from "react-redux";
import { onCreateWorkflow, onUpdateWorkflow } from "../../../../Redux/Actions/AgentActions";
import { FaSpinner } from "react-icons/fa";
import axios from "axios";
import { setAlert } from "../../../../Redux/Actions/AlertActions";
import { Tooltip } from "react-tooltip";

const WorkflowAutomationCalendarBookingTab = ({ handleChange, index, curElem, agentId }) => {

    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        create: false
    })
    const [events, setEvents] = useState([]);
    const calander = ["Cal.com", "Google Calendar"];


    const tooltips = {
        "Book Calendar": {
            functionName: "Enter a unique name for this calendar booking function. This will help you easily identify and manage it in your automation setting",
            description: "Provide a brief description of this function. Explain how and when it will book your calendar, so you can keep track of your workflow.",
            calendar: "Select the calendar service you want to integrate with. This will allow the AI agent to check availability and schedule events.",
            apiKey: "Enter the API key for the selected calendar service. This key is needed to authenticate and connect your calendar to the AI agent for availability checks.",
            eventTypeId: "Specify the Event Type ID if you’re using Cal.com. This ID is used to match specific event types when checking availability and scheduling",
            timezone: "Select the timezone for the calendar availability checks. This ensures that events are scheduled at the correct local time, avoiding any confusion"
        },
        "Check Calendar Availability": {
            functionName: "Enter a unique name for this calendar availability check function. This helps in managing checks in your automation settings.",
            description: "Provide a brief description of this function. Explain how and when it checks calendar availability, so you can keep track of your workflow.",
            calendar: "Select the calendar service you want to integrate with. This will allow the AI agent to check availability and schedule events.",
            apiKey: "Enter the API key for the selected calendar service. This key is needed to authenticate and connect your calendar to the AI agent for availability checks.",
            eventTypeId: "Specify the Event Type ID if you’re using Cal.com. This ID is used to match specific event types when checking availability and scheduling.",
            timezone: "Select the timezone for the calendar availability checks. This ensures that events are scheduled at the correct local time, avoiding any confusion"
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            create: true
        })
        if (curElem.id) {
            let obj = { ...curElem }
            dispatch(onUpdateWorkflow(obj, loader, setLoader))
        } else {
            let obj = { ...curElem, agentId }
            dispatch(onCreateWorkflow(obj, index, loader, setLoader))
        }
    }


    const handleFetchEvents = async (e) => {
        if (e.target.value) {
            let urlVal = `https://api.cal.com/v1/event-types?apiKey=${e.target.value}`
            await axios({
                method: "GET",
                url: urlVal
            }).then((res) => {
                if (res.data.event_types.length > 0) {
                    setEvents(res.data.event_types.map((curElem) => {
                        return {
                            id: curElem.id,
                            title: curElem.title
                        }
                    }))
                }
            }).catch((err) => {
                dispatch(setAlert("Please provide a valid API key.", "danger"))
                console.log(err)
            })
        }
    }


    const handleChangeTimezone = (e) => {
        let obj = {
            target: {
                name: "timezone",
                value: e.value
            }
        }
        handleChange(obj, index)
    }
    const currentTooltips = tooltips[curElem.type];
    return (
        <form onSubmit={handleSubmit}>
            <div className="input-wrap">
                <label className="label" htmlFor=""
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={currentTooltips.functionName}
                >Function Name</label>
                <input
                    className="input"
                    type="text"
                    name="functionName"
                    placeholder='Enter Function Name Here'
                    value={curElem.data[0]?.functionName}
                    onChange={(e) => handleChange(e, index)}
                    required

                />
            </div>
            <div className="input-wrap">
                <label className="label" htmlFor=""
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={currentTooltips.description}
                >Description</label>
                <input
                    className="input"
                    type="text"
                    name="description"
                    placeholder="When users ask for availability, check the calendar and provide available slots"
                    value={curElem.data[0]?.description}
                    onChange={(e) => handleChange(e, index)}
                    required
                />
            </div>

            <div className="input-wrap">
                <label className="label" htmlFor=""
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={currentTooltips.calendar}
                >Choose Calendar</label>
                <PrimeDropdown
                    value={curElem.data[0]?.calendar}
                    onChange={(e) => handleChange(e, index)}
                    options={calander}
                    placeholder="Select Type"
                    className="input"
                    name="calendar"
                // required
                />
            </div>
            {curElem.data[0]?.calendar === "Cal.com" ?
                <div className="input-wrap">
                    <label className="label" htmlFor=""
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={currentTooltips.apiKey}
                    >API Key (Cal.com)</label>
                    <input
                        className="input"
                        type="text"
                        placeholder='Enter API Key'
                        value={curElem.data[0]?.apiKey}
                        name="apiKey"
                        onChange={(e) => handleChange(e, index)}
                        required
                        onBlur={handleFetchEvents}
                    />
                </div> : null}

            <div className="input-wrap">
                <label className="label" htmlFor=""

                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={currentTooltips.eventTypeId}
                >Event Type ID ({curElem.data[0]?.calendar})</label>
                <PrimeDropdown
                    value={curElem.data[0]?.eventTypeId}
                    onChange={(e) => handleChange(e, index)}
                    options={events}
                    optionLabel="title"
                    placeholder="Enter Event Type ID"
                    className="input"
                    name="eventTypeId"
                // required
                />
            </div>

            <div className="input-wrap">
                <label className="label" htmlFor=""
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={currentTooltips.timezone}
                >Timezone</label>
                <TimezoneSelect
                    value={curElem.data[0]?.timezone ? curElem.data[0]?.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone}
                    onChange={(e) => handleChangeTimezone(e, index)}
                    className="input"
                    required
                />
            </div>
            <div className="btn-group mt-4">
                <button className="theme-btn" type="submit"><span>
                    {loader.create ? <>{curElem.id ? "Updating" : "Creating"} <FaSpinner className="spin" /></> : curElem.id ? "Update" : "Create"}
                </span></button>
            </div>
            <Tooltip id="my-tooltip" place="top-start" clickable />

        </form>
    )
}

export default WorkflowAutomationCalendarBookingTab;