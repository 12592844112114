import React, { useEffect, useRef, useState } from "react";

import { Dropdown, Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { AiFillCheckCircle } from "react-icons/ai";
import { FaSpinner } from "react-icons/fa";
import { PiChatTeardropDots } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import AgentHeader from "./AgentHeader";
import { onAskCopilot, onChangeAgent, onChangeGreeting, onSaveAgent } from "../../../../Redux/Actions/AgentActions";
import { fetchData, fetchDataPrimereact } from "../../../../Redux/Actions/CommonActions";
import { setAlert } from "../../../../Redux/Actions/AlertActions";
import { Tooltip } from "react-tooltip";

const AgentDesign = () => {
    const dispatch = useDispatch()
    const agent = useSelector(state => state.agent.data)
    const extra = useSelector(state => state.extra.data)

    const greetRef = useRef(null)

    const [temp, setTemp] = useState([])
    const [lang, setLang] = useState([])
    const [tone, setTone] = useState([])
    const [loader, setLoader] = useState({
        copilot: false,
        save: false
    })

    const [finalLang, setFinalLang] = useState({})

    const sensitivity = [
        'High',
        'Medium',
        'Low'
    ];

    const greeting = [
        { name: "First Name", value: "{First_Name}" },
        { name: "Last Name", value: "{Last_Name}" },
        { name: "Name", value: "{Name}" }
    ];

    const speakFirst = [
        'user',
        'agent'
    ];

    const [choosedTemp, setChoosedTemp] = useState(null);

    const chooseTemplate = (e) => {
        setChoosedTemp(e.target.value)
        dispatch(onChangeAgent("prompt", e.target.value.prompt))
    }

    const onCopilot = () => {
        let data = {
            id: agent.id
        }
        setLoader({
            ...loader,
            copilot: true
        })
        dispatch(onAskCopilot(data, loader, setLoader))
    }

    const handleChange = (e) => {
        let { name, value, checked } = e.target
        if (name === "callRecord") {
            value = checked ? 1 : 0
        }
        dispatch(onChangeAgent(name, value))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let isSpaneinName = true
        for (let i = 0; i < agent.name.length; i++) {
            if (agent.name[i] !== " ") {
                isSpaneinName = false
                break;
            }
        }
        if (isSpaneinName) {
            dispatch(setAlert("Please provide a valid name for an agent.", "danger"))
        } else {
            setLoader({
                ...loader,
                save: true
            })
            dispatch(onSaveAgent(agent, loader, setLoader))

        }
    }

    const handleAddGreetName = (e) => {
        const index = greetRef.current.selectionStart
        dispatch(onChangeGreeting(index, e.target.value))
    }

    const fetchTemplates = () => {
        let data = {}
        dispatch(fetchData("fetch-templates", data, setTemp))
    }

    const fetchLanguage = () => {
        let data = {}
        dispatch(fetchDataPrimereact("get-languages", data, setLang, false, false, "name", false, "name"))
    }

    const fetchTone = () => {
        let data = {}
        dispatch(fetchDataPrimereact("fetch-tone", data, setTone, false, false, "name", true, "name"))
    }

    useEffect(() => {
        if (agent.language) {
            let langggg = lang.find(({ code }) => code === agent.langCode)
            if (langggg) {
                setFinalLang(langggg)
            }
        }
    }, [agent.language, lang])

    useEffect(() => {
        fetchTemplates()
        fetchLanguage()
        fetchTone()
    }, [])

    return (
        <>
            <AgentHeader />
            {extra.isKnowledgeData ? null :
                <div className="alert alert-warning mt-2" role="alert">
                    Please add some data in knowledgebase to have conversation with the agent.
                </div>
            }
            <form className="account-block" onSubmit={handleSubmit}>
                <h6>Choose Agent Type</h6>
                <div className="select-group">
                    <ul>
                        <li>
                            <div className="select-group-single">
                                <span className="select-check"><AiFillCheckCircle /></span>
                                <h6>Inbound Agent</h6>
                                <p>An inbound AI calling agent will answer a call that is typically initiated by a customer to a call center or contact center i.e. the AI agent itself. The AI calling agent will then handle the call, talk to the caller and take action based on how it's setup.</p>
                                <input
                                    type="radio"
                                    name="agentType"
                                    checked={agent.agentType === "inbound" ? true : false}
                                    onChange={handleChange}
                                    value={"inbound"}
                                    required

                                />
                            </div>
                        </li>
                        <li>
                            <div className="select-group-single">
                                <span className="select-check"><AiFillCheckCircle /></span>
                                <h6>Outbound Agent</h6>
                                <p>An outbound AI calling agent is one that makes an outgoing call to a prospect to generate their interest in a company's product or service — or close a sale. The AI agent who makes the outbound calls are usually sales or customer service representatives type and will then handle the call, talk to the caller and take action based on how it's setup.</p>
                                <input
                                    type="radio"
                                    name="agentType"
                                    checked={agent.agentType === "outbound" ? true : false}
                                    onChange={handleChange}
                                    value={"outbound"}
                                    required
                                />
                            </div>
                        </li>
                    </ul>
                </div>

                <div className='row'>
                    <div className='col-6'>
                        <div className="input-wrap">
                            <label className="label" htmlFor=""
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Select the gender that the AI agent will be perceived as when interacting with callers. This will help set the tone and style of communication"
                            >Agent Gender</label>
                            <Dropdown
                                value={agent.gender}
                                onChange={handleChange}
                                options={["Male", "Female"]}
                                placeholder="Select Gender"
                                className="input"
                                name="gender"
                                required

                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="input-wrap">
                            <label className="label" htmlFor=""
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Choose the tone of voice for the AI agent. Options range from formal and professional to casual and friendly, depending on how you want the agent to interact with callers."
                            >Agent Tone</label>
                            <Dropdown
                                value={agent.tone}
                                onChange={(e) => handleChange(e)}
                                options={tone}
                                placeholder="Select Tone"
                                className="input"
                                required
                                name="tone"

                            />
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-12'>
                        <div className="input-wrap">
                            <label className="label" htmlFor=""
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Select the primary language that the AI agent will use to communicate with callers. Ensure that the language matches your target audience"
                            >Agent Language</label>
                            <Dropdown
                                value={finalLang}
                                onChange={handleChange}
                                options={lang}
                                optionLabel="name"
                                placeholder="Select Language"
                                className="input"
                                required
                                name="language"

                            />
                        </div>
                    </div>
                    {/* <div className='col-6'>
                        <div className="input-wrap">
                            <label className="label" htmlFor=""
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Choose the accent that the AI agent will have during calls, allowing for a more localized or personalized experience for your audience"
                            >Agent Accent</label>
                            <Dropdown
                                value={agent.accent}
                                onChange={handleChange}
                                options={accents}
                                placeholder="Select Accent"
                                className="input"
                                required
                                name="accent"

                            />
                        </div>
                    </div> */}
                </div>


                <div className="input-wrap">
                    <label className="label" htmlFor=""
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Provide a brief description of your AI agent’s purpose and key functionalities"
                    >Agent Description</label>
                    <textarea
                        className="input"
                        type="text"
                        name="description"
                        value={agent.description}
                        onChange={handleChange}
                        rows={5}
                        required

                    />
                </div>
                <div className="input-wrap">
                    <label className="label" htmlFor=""
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Set the initial message that your AI agent will use to greet callers. This is the first impression and sets the tone for the entire interaction"
                    >Greeting Message</label>
                    <div style={{ display: "flex" }}>
                        <input
                            ref={greetRef}
                            className="input"
                            type="text"
                            name="greetingMessage"
                            value={agent.greetingMessage}
                            onChange={handleChange}
                            required
                        />
                        <PrimeDropdown
                            onChange={handleAddGreetName}
                            options={greeting}
                            optionLabel="name"
                            placeholder="@"
                            className="input fill"
                            style={{ width: "80px" }}
                        />
                    </div>
                </div>


                <div className="input-wrap">
                    <div className="input-head">
                        <label className="label" htmlFor=""
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Define the specific instructions or script that will guide the AI agent during the conversation. This helps the agent respond accurately to various scenarios"
                        >Agent Prompt</label>
                        <div className="agent-template">
                            <PrimeDropdown
                                value={choosedTemp}
                                onChange={chooseTemplate}
                                options={temp}
                                optionLabel="name"
                                placeholder="Choose From Template"
                                className="input fill"
                            />
                            <button className="theme-btn" type="button" onClick={onCopilot}>
                                <span><PiChatTeardropDots /> Ask Copilot {loader.copilot ? <FaSpinner className="spin" /> : null}</span>
                            </button>
                        </div>
                    </div>
                    <textarea
                        className="input mt-4"
                        name="prompt"
                        placeholder="You can write manually here"
                        value={agent.prompt}
                        onChange={handleChange}
                        rows={15}
                        required

                    />

                </div>

                {/* <div className="input-wrap">
                    <label className="label" htmlFor="">LLM Options</label>
                    <PrimeDropdown
                        value={agent.llm}
                        onChange={handleChange}
                        options={cities}
                        placeholder="Select Type"
                        name="llm"
                        className="input"
                        required
                    />
                </div> */}

                <div className="input-wrap">
                    <label className="label" htmlFor=""
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Decide whether the AI agent or the caller should start the conversation. This setting influences the flow of the interaction"
                    >Who Speaks First</label>
                    <PrimeDropdown
                        value={agent.whoSpeakFirst}
                        onChange={handleChange}
                        options={speakFirst}
                        placeholder="Select Type"
                        className="input"
                        name="whoSpeakFirst"
                        required

                    />
                </div>

                <div className="input-wrap">
                    <label className="label" htmlFor=""
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Adjust the sensitivity of the AI agent's responses.Higher sensitivity makes the agent react faster to small hints, while lower sensitivity gives more thoughtful responses."
                    >Interaction Sensitivity</label>
                    <PrimeDropdown
                        value={agent.interruptionSensitivity}
                        onChange={handleChange}
                        options={sensitivity}
                        placeholder="Select Type"
                        className="input"
                        name="interruptionSensitivity"
                        required

                    />
                </div>
                <div className="input-wrap">
                    <label className="label" htmlFor=""
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Enable or disable the recording of calls handled by the AI agent. Recordings can be useful for quality assurance, training, or compliance purposes"
                    >Allow Call Recording</label>
                    <div className="inp-group">
                        <span className="custom-switch">
                            <input
                                type="checkbox"
                                name="callRecord"
                                checked={+agent.callRecord === 1 ? true : false}
                                onChange={handleChange}

                            />
                            <span className="checkMark"></span>
                            <span>Allowed</span>
                        </span>
                    </div>
                </div>

                <div className="btn-group mt-4">
                    {/* <button className="theme-btn light"><span>Cancel</span></button> */}
                    <button type="submit" className="theme-btn"><span>{loader.save ? <>Saving <FaSpinner className="spin" /></> : "Save Changes"}</span></button>
                </div>
                <Tooltip id="my-tooltip" place="top-start" clickable />
            </form >

        </>
    )
}

export default AgentDesign;