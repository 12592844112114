import React, { useEffect, useState } from 'react'
import { onSaveAgent } from '../../../../Redux/Actions/AgentActions'
import { useDispatch } from 'react-redux'
import { FaSpinner, FaTrash } from 'react-icons/fa'
import { MdOutlinePlayCircle, MdOutlineStopCircle } from 'react-icons/md'
import { themeColor } from '../../../../Global/Global'
import CommonAlert from '../../../Common/CommonAlert'
import { onDeleteCampaign } from '../../../../Redux/Actions/CommonActions'
let music = false
const VoiceCloneRow = (props) => {
    const dispatch = useDispatch()
    const [play, setPlay] = useState(false)
    const [loader, setLoader] = useState({
        save: false,
        playRecording: false
    })

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false

    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: props.curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-clone-voice", data, props.cloneVoices, props.setCloneVoices, setSweet))
    }


    const handlePlay = () => {
        if (props.curElem.url) {
            props.setSelectedPlay(props.curElem.url)
            if (music !== false) {
                music.pause()
                setPlay(false)
            }
            setLoader({
                ...loader,
                playRecording: true
            })
            music = new Audio(props.curElem.url)
            music.play()
                .then(r => {
                    setLoader({
                        ...loader,
                        playRecording: false
                    })
                    setPlay(true)
                }
                )
                .catch(err => console.log(err));

            music.onended = () => {
                setPlay(false)
            }
        }
    }

    const handlePause = () => {
        if (music !== false) {
            music.pause()
            setPlay(false)
            music = false
            props.setSelectedPlay(false)
        }
    }

    const handleSelectVoice = () => {
        setLoader({
            ...loader,
            save: true
        })
        let obj = { ...props.curElem }
        obj.id = obj.cloneId
        obj.cloneId = props.curElem.id
        let data = { ...props.agent, voice: { type: "clone", data: obj } }
        dispatch(onSaveAgent(data, loader, setLoader, "updateRedux"))
    }

    useEffect(() => {
        return () => {
            if (music !== false) {
                music.pause();
            }
        }
    }, [])


    return (
        <tr>
            <td>
                {props.curElem.name}
            </td>
            <td>{props.curElem.type}</td>

            <td>{props.curElem.created}</td>
            <td className="text-end">
                <span className={`theme-btn ${props.agent.voice?.type === "clone" ? +props.agent.voice?.data?.cloneId === +props.curElem.id ? "" : "light" : "light"} me-1`} onClick={handleSelectVoice}>
                    {loader.save ? <>Selecting <FaSpinner className='spin' size={12} /> </> :
                        props.agent.voice?.type === "clone" ? +props.agent.voice?.data?.cloneId === +props.curElem.id ?
                            "Selected" : "Select" : "Select"
                    }
                </span>
                {play && (props.selectedPlay === props.curElem.url) ?
                    <MdOutlineStopCircle style={{ color: themeColor, fontSize: "23px", cursor: "pointer" }} title='Stop' onClick={handlePause} /> :
                    loader.playRecording ? <FaSpinner className='spin' style={{ color: themeColor, fontSize: "23px" }} /> :
                        <MdOutlinePlayCircle style={{ color: themeColor, fontSize: "23px", cursor: "pointer" }} title='Play' onClick={handlePlay} />
                }
                <FaTrash title='Delete' className='ms-1' style={{ color: themeColor, fontSize: "18px", cursor: "pointer" }} onClick={onDelete} />

            </td>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this voice?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </tr>
    )
}

export default VoiceCloneRow