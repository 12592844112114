import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa6'
import { onCreateVoiceClone, onSaveVoiceClone } from '../../../../Redux/Actions/AgentActions'
import { useDispatch } from 'react-redux'

const CreateModal = (props) => {
    const dispatch = useDispatch()
    const [name, setName] = useState("")
    const [loader, setLoader] = useState(false)


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        if (props.selectedVoice) {
            let data = {
                name: props.selectedVoice.name,
                type: "clone",
                voiceId: props.selectedVoice.voice_id,
                voiceName: props.selectedVoice.name,
                agentId: props.agent.id,
                data: {
                    name: props.selectedVoice.name,
                    type: "clone",
                    voiceId: props.selectedVoice.voice_id,
                    voiceName: props.selectedVoice.name,
                    agentId: props.agent.id,
                }
            }
            dispatch(onSaveVoiceClone(data, props.fetchCloneVoices, setName, props.handleClose, setLoader, props.setSelectedVoice))
        } else {
            let data = {
                name,
                url: props.fileData.url,
                type: props.fileData.type,
                localPath: props.fileData.localPath,
                agentId: props.agent.id
            }
            dispatch(onCreateVoiceClone(data, props.fetchCloneVoices, props.setFileData, setName, props.handleClose, setLoader))
        }
    }
    useEffect(() => {
        if (props.selectedVoice) {
            setName(props.selectedVoice.name);
        } else {
            setName("")
        }
    }, [props.selectedVoice])

    useEffect(() => {
        if (!props.show) {
            props.setSelectedVoice(false)
            setName("")
        }
    }, [props.show])

    return (
        <Modal className="theme-modal" show={props.show} backdrop="static" centered>
            <Modal.Body>
                <span className="modal-close" onClick={props.handleClose}><AiOutlineClose /></span>
                <form className="modal-content" onSubmit={handleSubmit}>
                    <h3 className="text-center">Give your voice a name</h3>
                    <div className="modal-card mt-4">
                        <div className="input-wrap">
                            <label className="label" htmlFor="">Voice Name</label>
                            <input
                                className="input"
                                type="text"
                                name="name"
                                placeholder="Enter Voice Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                    </div>
                    <div className="btn-group mt-4">
                        <button className="theme-btn light" type='button' onClick={props.handleClose}><span>Cancel</span></button>
                        <button className="theme-btn" type='submit'><span>{loader ? <>Creating <FaSpinner className='spin' /> </> : "Create"}</span></button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateModal