import React, { useEffect, useState } from 'react'
import Recording from './Recording'
import { LiaHeadsetSolid } from 'react-icons/lia'
import { FiUpload } from 'react-icons/fi'
import { FaSpinner } from 'react-icons/fa'
import { fetchData, onUploadMedia } from '../../../../Redux/Actions/CommonActions'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { themeColor } from '../../../../Global/Global'
import CreateModal from './CreateModal'
import VoiceCloneRow from './VoiceCloneRow'
import { Dropdown } from 'primereact/dropdown';

const VoiceClone = () => {
    const dispatch = useDispatch()
    const agent = useSelector(state => state.agent.data)
    const elevenLabs = useSelector(state => state.social.elevenLabs)
    const [cloneVoices, setCloneVoices] = useState([])
    const [play, setPlay] = useState(false)
    const [selectedPlay, setSelectedPlay] = useState(false)
    const [percent, setPercent] = useState(0)
    const [voice, setVoice] = useState([])
    const [selectedVoice, setSelectedVoice] = useState(false);
    let allowedExt = ['audio/mpeg'];

    const [loader, setLoader] = useState({
        fetch: true,
        upload: false,
        record: false
    })
    const [fileData, setFileData] = useState({
        url: "",
        type: "",
        localPath: ""
    })

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const onCompleteLoad = (url, type, vv, localPath) => {
        setFileData({
            url: url,
            type: type,
            localPath
        })
    }

    const onInputFile = (e, type) => {
        let allowedSize = 5000000
        if (e.target.files.length > 0) {
            let upFile = e.target.files[0]
            if (allowedExt.includes(upFile.type)) {
                const reader = new FileReader();
                reader.readAsArrayBuffer(e.target.files[0]);
                reader.onloadend = (e) => {
                    const ctx = new AudioContext();
                    const audioArrayBuffer = e.target.result;
                    ctx.decodeAudioData(audioArrayBuffer, data => {
                        // this is the success callback
                        const duration = data.duration;
                        if (+duration <= 30) {
                            if (upFile.size < allowedSize) {
                                const formData = new FormData()
                                formData.append('upload_type', type)
                                formData.append('file_type', "")
                                formData.append('file', upFile)
                                let fun = type === "recording" ? handleShow : false
                                dispatch(onUploadMedia(formData, onCompleteLoad, loader, setLoader, setPercent, type, fun))
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Max allowed size for Music File is 5MB!',
                                    confirmButtonColor: themeColor
                                })
                            }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Maximum allowed duration is 30 seconds to clone your voice!',
                                confirmButtonColor: themeColor
                            })
                        }
                    }, error => {
                        console.error(error);
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }
   
    const fetchCloneVoices = () => {
        dispatch(fetchData("list-clone-voice", {}, setCloneVoices, loader, setLoader))
    }
    const fetchElevenlabsVoice = () => {
        dispatch(fetchData("fetch-elevenlabs-voices", {}, setVoice, false, false, false, "shortDataLg"))
    }

    useEffect(() => {
        fetchCloneVoices()
        fetchElevenlabsVoice()
    }, [])
    return (
        <div className="col-lg-5 col-12">
            <div className="account-block">
                <div className="title-flex justify-content-start gap-1">
                    <span className="theme-icon large"><LiaHeadsetSolid /></span>
                    <h6>Clone your Voice</h6>
                </div>
                {elevenLabs.length === 0 ?
                    <div className="alert alert-warning p-3 mt-2" role="alert">
                        Please go to the integrations tab to connect your ElevenLabs account.
                    </div>
                    :
                    <>
                        <div className="row">
                            <div className="col-12">
                                <div className="uploader left">
                                    <div className="uploader-icon">
                                        {loader.upload ? <FaSpinner className='spin' /> :
                                            <FiUpload />}
                                    </div>
                                    <div className="uploader-txt">
                                        {fileData.type === "voiceover" ?
                                            <>
                                                <h6>{fileData.url.slice(0, 40)}</h6>
                                            </> :
                                            <>
                                                <h6>Upload File</h6>
                                                <p>Click here to upload Voice</p>
                                            </>
                                        }
                                    </div>
                                    <input
                                        type="file"
                                        onChange={(e) => onInputFile(e, "voiceover")}
                                    />
                                </div>
                                <div className='text-end'>
                                    <button
                                        className='theme-btn light mt-2 text-end'
                                        style={fileData.type === "voiceover" ? fileData.url ? {} : { cursor: "not-allowed" } : { cursor: "not-allowed" }}
                                        onClick={handleShow}
                                        disabled={fileData.type === "voiceover" ? fileData.url ? false : true : true}
                                    >
                                        <span>Create Voice</span>
                                    </button>
                                </div>

                            </div>
                            <div className='col-12 my-1'>
                                <div class="dividline"><span>or</span></div>
                            </div>


                            <div className="col-12">
                                <Recording
                                    onInputFile={onInputFile}
                                    loaderRecord={loader}
                                />
                            </div>
                        </div>
                        <div className='col-12 my-1'>
                            <div class="dividline"><span>or</span></div>
                        </div>
                        <div className="col-12">
                            <div className="input-wrap">
                                <Dropdown
                                    value={selectedVoice}
                                    options={voice}
                                    onChange={(e) => setSelectedVoice(e.value)}
                                    optionLabel='name'
                                    placeholder="Select Voice"
                                    className="input"
                                    name="elevenLabsVoice"
                                    required
                                />

                            </div>
                            <div className='text-end'>
                                <button className='theme-btn light mt-2 text-end'
                                    onClick={handleShow}
                                    disabled={!selectedVoice}
                                    style={{ cursor: selectedVoice ? "" : "not-allowed" }}
                                >
                                    <span >Save Voice</span>
                                </button>
                            </div>
                        </div>
                        <div className="mt-3">
                            <hr />
                            <h6>Your Clone Voices</h6>
                            <div className="table-wrap mt-4">
                                <div className="table-responsive">
                                    <table className="table theme-table">
                                        <thead>
                                            <tr>
                                                <th>Voice Name</th>
                                                <th>Type</th>
                                                <th>Created</th>
                                                <th className="text-end">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cloneVoices.length > 0 ?
                                                cloneVoices.map((curElem, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <VoiceCloneRow
                                                                curElem={curElem}
                                                                agent={agent}
                                                                cloneVoices={cloneVoices}
                                                                setCloneVoices={setCloneVoices}
                                                                play={play}
                                                                setPlay={setPlay}
                                                                setSelectedPlay={setSelectedPlay}
                                                                selectedPlay={selectedPlay}
                                                            />
                                                        </React.Fragment>
                                                    )
                                                })
                                                :
                                                <tr className='text-center my-4'>
                                                    <td colSpan={6}>
                                                        {loader.fetch ?
                                                            <FaSpinner className='spin' style={{ fontSize: 25, color: "#000" }} />
                                                            : "No voice available yet!"}
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        </div>
                    </>
                }
            </div>
            <CreateModal
                setSelectedVoice={setSelectedVoice}
                voice={voice}
                selectedVoice={selectedVoice}
                show={show}
                agent={agent}
                handleClose={handleClose}
                fileData={fileData}
                setFileData={setFileData}
                fetchCloneVoices={fetchCloneVoices}
            />
        </div>
    )
}

export default VoiceClone